import React from "react"
import SEO from "../components/molecules/SEO"
import Container from "../components/atoms/Container"
import styled from "styled-components";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {graphql, Link} from "gatsby";

const Holder = styled.div`
  background-color: ${props => props.theme.colours.yellow};
  color: ${props => props.theme.colours.black};
  padding: 4rem 0;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Text = styled.div`
  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;
const Image = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-left: calc( 100/3 * 1% + 2rem );
  }
`;

const NotFoundPage = ({data}) => {
  const image = getImage(data.imageSharp);
  return (
    <>
      <SEO title="404: Not found" />
      <Holder>
        <Container>
          <h1>Whoops! Cat's got our tongue.</h1>
          <Body>
            <Text>
              <p>Sorry for the mix-up — can we take you back home?</p>
              <p><Link className="button large" to="/">Home</Link></p>
            </Text>
            <Image>
              <GatsbyImage alt="404 error" image={image} />
            </Image>
          </Body>
        </Container>
      </Holder>
    </>
  )
};

export default NotFoundPage;

export const fourOhFourQuery = graphql`
  query fourOhFourQuery {
    imageSharp(fluid: {originalName: {eq: "four-o-four.png"}}) {
      id
      gatsbyImageData(width: 1200)
    }
  }
`;
